import React from "react";
import styled from "styled-components";

const HomeContainer = styled.section`
  width: 100vw;
  min-height: 100vh;
  background: #0056a2;
  background-image: url("././images/croixoccitane.png");
  background-repeat: no-repeat;
  background-position: left -26% center;
  background-size: 35%;
  display: grid;
  grid-template-rows: repeat(2, 50%);
  @media(min-width: 768px){
    background-size: 300px;
    background-position: left -140px center;
  }
`;
const Title = styled.article`
  width: 100%;
  height: 100%;
  background-image: url("././images/CDBmobile.png");
  background-repeat: no-repeat;
  background-position: top 25% right 0%;
  background-size: 80%;
  @media (min-width: 768px) {
    background-image: url("././images/CDBordi.png");
  background-repeat: no-repeat;
  background-position: top 25% right 0%;
  background-size: 80%;
  }
`;
const BlueContainer = styled.article`
  width: 80%;
  height: 90%;
  align-self: baseline;
  min-height: 170px;
  background-color: rgb(82,186,235);
  padding: 0 7%;
  margin: 20% 0 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.35);
  transition: width .2s ease-in-out;
  @media (min-width: 500px) {
    margin: 12% 0% 0 20%;
  }
  @media (min-width: 768px) {
    margin: 6% 0% 0 35%;
    width: 65%;
  }
  @media (min-width: 1024px) {
    margin: 4% 0% 0 45%;
    width: 55%;
  }
`;

const TextCard = styled.h1`
  font-size: 4.4vw;
  color: white;
  font-weight: 400;
  @media (min-width: 500px) {
    font-size: 20px;
  }
  &::selection {
    color: #478ace;
    background-color: white;
  }
  &::-moz-selection {
    color: #478ace;
    background-color: white;
  }
`;
const TextSpan = styled.p`
  color: white;
  font-weight: 300;
  font-size: 3.6vw;
  margin: 5.5% 0 15% 0;
  @media (min-width: 500px) {
    font-size: 16px;
    margin: 7% 0 10% 0;
  }
  &::selection {
    color: #478ace;
    background-color: white;
  }
  &::-moz-selection {
    color: #478ace;
    background-color: white;
  }
`;

const ContactText = styled.p`
  color: white;
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  font-size: 3vw;
  @media (min-width: 500px) {
    font-size: 18px;
  }
  `
const ContactNumber = styled.a`
  text-decoration: none;
  font-size: 3vw;
  font-weight: 500;
  letter-spacing: .15rem;
  color: white;
  display: block;
  transition: color .35s ease-in-out;
  width: fit-content;
  margin-left: 10px;
  transition: color 0.6s ease-in-out;

  @media (min-width: 568px) {
    font-size: 22px;
  }
  &:hover {
    color: #0056a2;
  }
`;

const Accueil = () => {
  return (
    <HomeContainer>

      <Title></Title>

      <BlueContainer>
        <TextCard>
          Entreprise de nettoyage, pour particuliers et professionnels
        </TextCard>
        <TextSpan>dans l'Aude et le Minervois</TextSpan>
        <ContactText>Philippe Belhache <ContactNumber href={"tel:+33602061897"}> 06 02 06 18 97</ContactNumber></ContactText>
      </BlueContainer>
    </HomeContainer>
  );
};

export default Accueil;

import React, { useState } from "react";
import styled from "styled-components";
import { Slides } from "./Slides";

const SliderContainer = styled.section`
  width: 90%;
  max-width: 500px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  @media(min-width: 1020px){
    width: 40%;
    margin: 0 0 0 10%;
  }
`;

const ImagesContainer = styled.article`
  width: 100%;
`;
const ImagesSlider = styled.img`
  object-fit: cover;
  width: 100%;
  height: 450px;
  border-radius: 50px 0 50px 0;
  @media(min-width: 768px){
    height: 450px;
  }
  @media(min-width: 1020px){
    border-radius: 0;
    height: 500px;
  }
`;

const ButtonContainer = styled.article`
  width: 50vw;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;
const ArrowDiv = styled.div`
  padding: 10px 12px;
  background-color: white;
  position: relative;
  top: -15px;
  right: 50%;
  transform: translate(-50%, -30%);
  @media(min-width: 1020px){
    transform: translate(50px, -60%);
  }
`;
const ButtonArrow = styled.img`
  width: 30px;
  height: auto;
  cursor: pointer;
`;

const ImageSlider = () => {
  const [current, setCurrent] = useState(0);
  const length = Slides.length;

  if (!Array.isArray(Slides) || Slides.length <= 0) {
    return null;
  }

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <SliderContainer>
      {Slides.map((slide, index) => {
        return (
          <ImagesContainer key={index} className={index === current ? "slide active" : "slide"}>
            {index === current && (
              <ImagesSlider src={process.env.PUBLIC + slide.image} alt="Entreprise Coup de Balai" />
            )}
          </ImagesContainer>
        );
      })}
      <ButtonContainer>
        <ArrowDiv>
          <ButtonArrow onClick={prevSlide} src={process.env.PUBLIC + "../../images/left.svg"} />
        </ArrowDiv>
        <ArrowDiv>
          <ButtonArrow onClick={nextSlide} src={process.env.PUBLIC + "../../images/right.svg"} />
        </ArrowDiv>
      </ButtonContainer>
    </SliderContainer>
  );
};

export default ImageSlider;
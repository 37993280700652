import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';
import Accueil from './components/Accueil';
import Entreprise from './components/Entreprise';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';

import AOS from "aos";
import "aos/dist/aos.css";

function App() {

AOS.init();

  return (
    <Router>
      <Accueil/>
      <Entreprise />
      <Services />
      <Contact />
      <Footer />
    </Router>
  );
}

export default App;

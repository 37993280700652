import React from "react";
import styled from "styled-components";

const ContactContaint = styled.section`
  padding: 20px 0;
`;

const TitleContainer = styled.section`
  width: 90%;
  margin: 200px 0 80px 0;
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  text-transform: uppercase;
  letter-spacing: .1rem;
  font-weight: 500;
  color: #0056a2;
  margin-left: 10px;
`;

const Line = styled.article`
  background-color: #0056a2;
  height: 2px;
  width: 100%;
`;

const ContactContainer = styled.section`
padding: 10px 0 120px 0;
  @media (min-width: 768px) {
    padding: 80px 0 120px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const TextContainer = styled.article`
  margin: 0 10% 50px 10%;
  @media (min-width: 768px) {
    margin: 0 5% 50px 10%;
  }
`;
const Description = styled.p`
  margin-bottom: 20px;
  color: #0056a2;
  font-size: 16px;
  line-height: 1.8rem;
  @media(min-width: 768px){
    font-size: 18px;
    margin-right: 10%;
  }
  &::selection {
    color: white;
    background-color: #478ace;
  }
  &::-moz-selection {
    color: white;
    background-color: #478ace;
  }
`;

const InfoContainer = styled.article`
  margin: 100px auto 0 auto;
  width: fit-content;
  border: 2px solid #52baeb;
  border-radius: 5px;
  padding: 15px 25px;
  @media (min-width: 768px) {
    margin: 0 10% 50px 5%;
  }
`;
const ContactInfo = styled.p`
  padding: 10px 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #52baeb;
`;
const Logo = styled.img`
  width: 18px;
  height: auto;
  margin-right: 8px;
`;
const ContactAnchor = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: #52baeb;
  box-shadow: inset 0 -2px 0 #52baeb, 0 2px 0 #52baeb;
  transition: box-shadow .35s ease-in-out;
  &:hover {
    box-shadow: inset 0 -15px 0 #52baeb, 0 1px 0 #52baeb;
    color: #0056a2;
  }
  @media(min-width: 768px){
    font-size: 20px;
  }
`

const Contact = () => {
  return (
    <ContactContaint>
      <TitleContainer>
        <Line data-aos="fade-right" data-aos-offset="300"></Line>
        <Title data-aos="fade-up" data-aos-offset="300">Contact</Title>
      </TitleContainer>

      <ContactContainer>
        <TextContainer data-aos="fade-in" data-aos-duration="1500" data-aos-offset="300">
          <Description>Besoin d'informations complémentaires ou vous souhaitez faire appel à nos services ?</Description>
          <Description>Vous pouvez nous contacter par téléphone ou par email.</Description>
        </TextContainer>

        <InfoContainer data-aos="zoom-in-up" data-aos-duration="1500" data-aos-offset="200">
          <ContactInfo>Philippe Belhache</ContactInfo>
          <ContactInfo>
            <Logo src={process.env.PUBLIC_URL + "./images/phone.png"}></Logo>
            <ContactAnchor href={"tel:+33602061897"}>+33 6 02 06 18 97</ContactAnchor>
          </ContactInfo>
          <ContactInfo>
            <Logo src={process.env.PUBLIC_URL + "./images/mail.png"}></Logo>
            <ContactAnchor href={"mailto:uncoupdebalai@free.fr"}>uncoupdebalai@free.fr</ContactAnchor>
          </ContactInfo>
        </InfoContainer>
      </ContactContainer>
    </ContactContaint>
  );
};

export default Contact;

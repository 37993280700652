import React from "react";
import styled from "styled-components";

const MentionContainer = styled.section`
  width: 100%;
  padding: 20px 20px;
  margin: 50px auto;

  border: 1px solid #FFFFFF75;
  border-radius: 5px;

  @media screen and (min-width: 768px){
    width: 60%;
  }
`;

const MentionArticle = styled.article`
  width: 100%;
  margin: 50px auto;
`;

const MentionText = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  color: #FFFFFF75;
  margin: 5px 0;
  line-height: 1.4rem;
`;

const MentionsLegales = () => {
  return (
    <MentionContainer>
      <MentionText>Mentions Légales du site www.coupdebalai.net</MentionText>

      <MentionText>
        Le site est la propriété de Philippe Belhache, ainsi que tout son
        contenu. La structure générale ainsi que les textes et images. Ces
        règles s'appliquent à tout le site web sauf mention contraire.
      </MentionText>

      <MentionArticle>
        <MentionText>
          Dénomination ou raison sociale : Coup de balai
        </MentionText>
        <MentionText>
          Siège social : 2 rue de la petite fontaine, 11160
          Caunes-Minervois
        </MentionText>
        <MentionText>Numéro de téléphone : 06.02.06.18.97</MentionText>
        <MentionText>Adresse email : uncoupdebalai@free.fr</MentionText>
      </MentionArticle>

      <MentionArticle>
        <MentionText>N° de SIRET : 38097201800036</MentionText>
        <MentionText>Code APE : 8121Z</MentionText>
        <MentionText>N° d'établissement : 00036</MentionText>
      </MentionArticle>

      <MentionArticle>
        <MentionText>
          Hébergeur : OVH - 2 rue Kellermann, 59100 Roubaix
        </MentionText>
      </MentionArticle>

      <MentionText>
        Nous n'utilisons aucun cookies & aucune donnée n'est conservée. 
      </MentionText>

    </MentionContainer>
  );
};

export default MentionsLegales;

import React, { useState } from "react";
import styled from "styled-components";
import MentionsLegales from "./MentionsLegales/MentionsLegales";

const FooterContainer = styled.footer`
  width: 100vw;
  background-color: #0056a2;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  border-radius: 50px 0 0 0;
  box-shadow: inset 8px 6px 20px rgb(0,0,0, 0.2);
  ${'' /* @media(min-width: 768px){
    flex-direction: row;
    padding: 20px 5%;
  } */}
`;
const DevelopContent = styled.p`
  color: white;
  font-size: 12px;
  margin: 20px 0;
`;
const DevelopBy = styled.a`
  text-decoration: none;
  color: #52baeb;
`;

const MentionBtn = styled.p`
  color: white;
  font-size: 12px;
  margin: 10px 0;
  padding: 5px 10px;
  border: 1px solid white;
  cursor: pointer;
`

const Footer = () => {
  const [isMentions, setIsMentions] = useState(false);

  const handleMention = () => setIsMentions(!isMentions);

  return (
    <FooterContainer>
      <DevelopContent>Coup de Balai - Tous droits réservés.</DevelopContent>
      <MentionBtn onClick={handleMention} style={{ backgroundColor: isMentions ? "#52baeb" : "" }}>Mentions Légales</MentionBtn>

      {isMentions && <MentionsLegales />}

      <DevelopContent>Site développé par <DevelopBy href={"mailto:remilopez.dev@gmail.com"}>Rémi Lopez</DevelopBy></DevelopContent>
    </FooterContainer>
  );
};

export default Footer;

export const Slides = [
  {
    image : '../../images/phil.jpg',
    // images : `${process.env.PUBLIC_URL}/images/phil.jpg`,
  },
  {
    image : '../../images/vitre2.jpg'
  },
  {
    image : '../../images/vitre3.jpg'
  },
  {
    image : '../../images/vitre4.jpg'
  },
  {
    image : '../../images/cdb-eglise1.jpg'
  },
  {
    image : '../../images/cdb-eglise2.jpg'
  },
  {
    image : '../../images/cdb-eglise3.jpg'
  },
  {
    image : '../../images/cdb-eglise4.jpg'
  },
]
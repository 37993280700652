import React from "react";
import styled from "styled-components";
import ImageSlider from "./Slider/ImageSlider";

const EntrepriseContainer = styled.section`
  margin: 240px 0 200px 0;
`;

const TitleContainer = styled.article`
  width: 90%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`;
const Title = styled.h2`
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 500;
  color: #0056a2;
  margin-left: 10px;
`;
const Line = styled.div`
  background-color: #0056a2;
  height: 2px;
  width: 100%;
`;

const MediaContainer = styled.section`
  @media (min-width: 1020px) {
    width: 100vw;
    padding: 20px 5% 20px 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`;
const TextContainer = styled.article`
  z-index: 200;
  padding: 0 10%;
  @media (min-width: 1020px) {
    padding: 30px 5% 0 50px;
    background-color: white;
    transform: translate(-50px, 45px);
  }
`;
const Description = styled.p`
  padding: 10px 0;
  font-size: 16px;
  text-align: justify;
  color: #0056a2;
  line-height: 1.8rem;
  &::selection {
    color: white;
    background-color: #478ace;
  }
  &::-moz-selection {
    color: white;
    background-color: #478ace;
  }
  @media (min-width: 1020px) {
    font-size: 14px;
    line-height: 1.6rem;
  }
`;
const Citation = styled.p`
  color: #0056a2;
  padding: 50px 10%;
  font-style: italic;
  text-align: center;
  font-size: 22px;
  font-weigth: 200;
  font-family: mohave;
  &::selection {
    color: white;
    background-color: #478ace;
  }
  &::-moz-selection {
    color: white;
    background-color: #478ace;
  }
  @media (min-width: 1020px) {
    font-size: 18px;
    line-height: 1.6rem;
  }
`;
const ContactezNous = styled.p`
  font-size: 16px;
  text-align: justify;
  color: #0056a2;
  padding: 10px 0;
  line-height: 1.8rem;
  &::selection {
    color: white;
    background-color: #478ace;
  }
  &::-moz-selection {
    color: white;
    background-color: #478ace;
  }
  @media (min-width: 1020px) {
    font-size: 14px;
    line-height: 1.6rem;
  }
`;
const Contact = styled.a`
  text-decoration: none;
  color: #0056a2;
  font-weight: 500;
  box-shadow: inset 0 -2px 0 #52baeb, 0 2px 0 #52baeb;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
  &:hover {
    box-shadow: inset 0 -18px 0 #52baeb, 0 1px 0 #52baeb;
    color: white;
  }
  &::selection {
    color: white;
    background-color: #478ace;
  }
  &::-moz-selection {
    color: white;
    background-color: #478ace;
  }
`;

const Entreprise = () => {
  return (
    <EntrepriseContainer>
        <TitleContainer>
          <Line data-aos="fade-right"></Line>
          <Title data-aos="fade-up">Notre Entreprise</Title>
        </TitleContainer>

      <MediaContainer>
        <TextContainer>
            <Description data-aos="fade-in" data-aos-duration="1800">
              Notre société de nettoyage porte une attention particulière à la
              relation sociale, au respect de votre intérieur et de votre vie
              privée.
            </Description>
            <Description  data-aos="fade-in" data-aos-duration="1800">
              Nous faire confiance, c'est tout simplement l'assurance d'un
              travail soigné, respectueux des lieux et de vos biens. Nous
              utilisons pour cela des produits respectueux de l'environnement,
              du matériel pour les accès difficiles.
            </Description>
            <Citation data-aos="zoom-in-down" data-aos-duration="1500">
              "La qualité de nos services est au cœur de nos engagements."
            </Citation>
            <ContactezNous  data-aos="fade-in" data-aos-duration="1800">
              Notre équipe est disponible et à l'écoute de vos besoins, demandez
              gratuitement votre devis en contactant Philippe Belhache par{" "}
              <Contact href={"tel:+33602061897"}>téléphone</Contact> ou par{" "}
              <Contact href={"mailto:uncoupdebalai@free.fr"}>mail</Contact>.
            </ContactezNous>
        </TextContainer>
        <ImageSlider />
      </MediaContainer>
    </EntrepriseContainer>
  );
};

export default Entreprise;

import React from "react";
import styled from "styled-components";

const ServicesContaint = styled.section`
  background-color: #0056a2;
  padding: 50px 0;
  border-radius: 50px 0;
  box-shadow: inset 8px 6px 20px rgb(0, 0, 0, 0.2),
    inset -8px -6px 20px rgb(0, 0, 0, 0.2);
`;

const TitleContainer = styled.article`
  width: 90%;
  margin-left: 10%;
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 500;
  color: white;
  margin-right: 10px;
`;
const Line = styled.div`
  background-color: white;
  height: 2px;
  width: 100%;
`;

const CardsContainers = styled.section`
  width: 90%;
  margin: 50px 5%;
  @media (min-width: 768px) {
    width: 80%;
    margin: 50px 10%;
    padding-bottom: 50px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 50%);
    gap: 5%;
  }
  @media (min-width: 1124px) {
    width: 80%;
    margin: 50px 10%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 30.3%);
    gap: 5%;
  }
  @media (min-width: 1800px) {
    width: 80%;
    margin: 50px 10%;
    display: flex;
    justify-content: space-between;
    gap: 5%;
  }
`;
const ServicesContainerBlue = styled.article`
  width: 90%;
  margin: 50px auto;
  padding: 40px 25px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid white;
  align-items: center;
  box-shadow: inset 8px 6px 20px rgb(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    margin: 20px auto;
  }
`;
const LogoSection = styled.img`
  width: 40px;
  height: auto;
  align-self: center;
`;
const TitreSectionBleu = styled.h4`
  margin: 40px 0 20px 0;
  font-size: 14px;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
  text-align: center;
`;

const DescriptionSectionBleu = styled.p`
  color: #e3e5e9;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2rem;
`;

const Services = () => {
  return (
    <ServicesContaint>
        <TitleContainer>
          <Title data-aos="fade-up" data-aos-offset="300">Services</Title>
          <Line data-aos="fade-right" data-aos-offset="300"></Line>
        </TitleContainer>

      <CardsContainers>
          <ServicesContainerBlue data-aos="zoom-in-up" data-aos-duration="1500" data-aos-offset="300">
            <LogoSection
              src={process.env.PUBLIC_URL + "./images/house.svg"}
              alt="remise en état"
            ></LogoSection>
            <TitreSectionBleu>Remise en état</TitreSectionBleu>
            <DescriptionSectionBleu>Nous assurons l'entretien ménager de vos locaux suite à des travaux ou rénovations</DescriptionSectionBleu>
          </ServicesContainerBlue>

          <ServicesContainerBlue data-aos="zoom-in-up" data-aos-duration="1500" data-aos-offset="300">
            <LogoSection
              src={process.env.PUBLIC_URL + "./images/window.svg"}
              alt="vitres"
            ></LogoSection>
            <TitreSectionBleu>Vitres</TitreSectionBleu>
            <DescriptionSectionBleu>Nous assurons le nettoyage de vos fenêtres, baies vitrées & rotondes</DescriptionSectionBleu>
          </ServicesContainerBlue>

          <ServicesContainerBlue data-aos="zoom-in-up" data-aos-duration="1500" data-aos-offset="300">
            <LogoSection
              src={process.env.PUBLIC_URL + "./images/desk.svg"}
              alt="parties communes et bureaux"
            ></LogoSection>
            <TitreSectionBleu>Parties communes & bureaux</TitreSectionBleu>
            <DescriptionSectionBleu>Nous effectuons un ménage régulier de vos espaces communs</DescriptionSectionBleu>
          </ServicesContainerBlue>

          <ServicesContainerBlue data-aos="zoom-in-up" data-aos-duration="1500" data-aos-offset="300">
            <LogoSection
              src={process.env.PUBLIC_URL + "./images/event.svg"}
              alt="évènements"
            ></LogoSection>
            <TitreSectionBleu>Évènements</TitreSectionBleu>
            <DescriptionSectionBleu>Nous assurons le ménage de vos salles de réceptions-mariages, salles des fêtes, sièges de sports collectifs & vestiaires</DescriptionSectionBleu>
          </ServicesContainerBlue>

          <ServicesContainerBlue data-aos="zoom-in-up" data-aos-duration="1500" data-aos-offset="300">
            <LogoSection
              src={process.env.PUBLIC_URL + "./images/antinuisible.png"}
              alt="évènements"
            ></LogoSection>
            <TitreSectionBleu>Lutte anti-nuisibles</TitreSectionBleu>
            <DescriptionSectionBleu>Dératisation, Désinfection, Désinsectisation. Nous nous engageons aux côtés des professionnels, des collectivités et des particuliers.</DescriptionSectionBleu>
          </ServicesContainerBlue>
      </CardsContainers>
    </ServicesContaint>
  );
};

export default Services;
